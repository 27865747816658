<template>
    <footer>
        <div class="left">
            <h2>Q-BOX</h2>
            <div class="links-container">
                <h3>Links</h3>
                <ul>
                    <li><a href="https://www.twitter.com/matumasa0202">Twitter</a></li>
                    <li><a href="https://www.instagram.com/matumasa0202">Instagram</a></li>
                    <li><a href="https://www.github.com/matumasadev02">GitHub</a></li>
                </ul>
            </div>
        </div>
        <small>&copy; Matsuoka Masahiro</small>
    </footer>
</template>

<script>
export default {
    name: "FooterEl",
}
</script>

<style scoped>
    footer {
        background-color: rgb(233, 233, 233);
        color: var(--primary-text-color);
        display: flex;
        width: calc(100% - 4rem);
        padding: 2rem;
        justify-content: space-between;
    }
    footer a {
        color: var(--primary-text-color);
        transition: .2s;
    }
    footer a:hover {
        color: var(--dark-primary-color);
    }
    footer h2 {
        font-size: xx-large;
        margin-bottom: 1rem;
    }
    footer h3 {
        font-size: x-large;
    }
    footer small {
        align-self: flex-end;
        justify-items: flex-end;
    }
    .links-container {
        margin: .5rem;
    }
    ul, li {
        list-style: none;
    }
    li {
        margin-top: 1rem;
    }
</style>