<template>
    <div class="form-container">
    <form
        action="https://script.google.com/macros/s/AKfycbzgDig8POVFcU1NK6tUtCzh--tXie42TeDMYLamOGYZ91Etir5efB0Z8pL0u0QSdH8/exec"
        method="post"
        name="questionForm"
    >
        <input type="hidden" name="action" value="add">
        <input type="hidden" name="userAgent" id="userAgent">
        <input type="hidden" name="dateTime" id="dateTime">
        <textarea name="question" id="question" cols="30" rows="10" placeholder="質問を入力..."></textarea>
        <br>
        <button v-on:click="sendQuestion()">送信!!</button>
    </form>
    </div>
</template>
<script>
export default {
    name:"SendQuestionWithForm",
    methods: {
        sendQuestion: function (){
            document.getElementById("userAgent").value = window.navigator.userAgent;
            document.getElementById("dateTime").value = new Date().toString();
            document.questionForm.submit();
        }
    },
}
</script>
<style scoped>
    .form-container {
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    textarea {
        border-radius: 4px;
        border: 2px solid var(--light-primary-color);
        outline: none;
        width: clamp(100px,70vw,1200px);
        font-size: clamp(1rem,1.5rem,10rem);
        margin: 2rem;
        padding: 1rem;
        transition: .3s;
    }
    textarea:focus {
        border: 2px solid var(--default-primary-color);
    }
    button {
        background-color: var(--default-primary-color);
        color: var(--text-primary-color);
        font-size: 1rem;
        padding: 1rem 2rem;
        outline: none;
        border: 2px solid var(--default-primary-color);
        border-radius: 4px;
        transition: .3s;
    }
    button:hover{
        background-color: var(--text-primary-color);
        color: var(--default-primary-color);
        border: 2px solid var(--default-primary-color);
    }
</style>