<template>
  <div class="home">
    <HeaderEl pageName="HOME" />
    <main>
      <SendQuestionWithForm />
    </main>
    <FooterEl/>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderEl from "@/components/HeaderEl.vue";
import SendQuestionWithForm from "@/components/SendQuestionWithForm.vue";
import FooterEl from "@/components/FooterEl.vue";

export default {
  name: "Home",
  components: {
    HeaderEl,
    SendQuestionWithForm,
    FooterEl,
  },
};
</script>
<style>
body {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
}
main {
  flex: 1;
  width: 100%;
  min-height: calc(100vh - 70px);
}
</style>