<template>
    <header>
      <router-link to="/">
        <h1>Q-BOX</h1>
      </router-link>
      <span>{{ pageName }}</span>
    </header>
    <div class="spacer"></div>
</template>

<script>
export default {
  name: "HeaderEl",
  props: {
    pageName: String,
  },
};
</script>

<style scoped>
  header {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #3f51b5cc;
    backdrop-filter: blur(8px);
    color: var(--text-primary-color);
    position: fixed;
    top: 0;
    left: 0;
  }
  header a {
    color: var(--text-primary-color);
    margin-left: 2rem;
    transition: .3s;
  }
  header a:hover {
    color: var(--light-primary-color);
  }
  header span {
    margin-right: 2rem;
  }
  .spacer {
    height: 70px;
    width: 100%;
  }
</style>
